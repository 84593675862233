<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <div
      class=""
      v-if="isDateLoaded"
      style="height: 100vh; width:100%;background: rgba(0,0,0,0.2); z-index: 10; position: absolute; backdrop-filter: blur(3px)"
    >
      <div class="d-flex align-center justify-center" style="height: 100vh">
        <v-progress-circular indeterminate size="78" />
      </div>
    </div>
    <AuthLayout>
      <template v-slot:auth-title>
        <p class="authoriz__title-text flex-left">Выберите роль:</p>
      </template>
      <template v-slot:auth-inputs>
        <ValidationProvider name="Роль" v-slot="{ errors }" :rules="'required'">
          <v-radio-group
            :error-messages="errors"
            color="#95c23d"
            v-model="select"
            @change="selectRole"
          >
            <v-radio
              color="#95c23d"
              v-for="(avRole, index) in availableRoles"
              :key="index"
              :value="index"
            >
              <template v-slot:label>
                <span
                  class="tabbar-overlay-tab__block tabbar-overlay-tab__block_fz-sm"
                  style="min-width: 240px!important;"
                  >{{ rolesDictionary[avRole.role] }}</span
                >
                <span
                  class="tabbar-overlay-tab__block tabbar-overlay-tab__block_fz-md"
                  style="min-width: 240px!important;"
                >
                  <fragment v-if="joinRoles.indexOf(avRole.role) === -1">
                    {{ avRole.organization_name }}
                  </fragment>
                  <fragment v-else-if="avRole.count">
                    Объектов: {{ avRole.count }}
                  </fragment>
                </span>
              </template>
            </v-radio>
          </v-radio-group>
        </ValidationProvider>
      </template>
      <template style="z-index: 1000;" v-slot:action>
        <div class="d-flex flex-right">
          <v-btn @click="logout" color="#EB5C6D" class="btn  btn_color_red ">
            Назад
          </v-btn>
          <v-btn
            @click="handleSubmit(endLogin)"
            class="btn btn_color_green btn-login ml-4 authoriz__btn authoriz__btn_choose_role"
            :disabled="select == null"
          >
            Войти
          </v-btn>
        </div>
      </template>
    </AuthLayout>
  </ValidationForm>
</template>

<script>
import AuthLayout from "@/components/layouts/AuthLayout";
import ValidationForm from "@/components/ValidationField/ValidationForm";
import rolesDictionary from "@/helpers/userInterface";
import tabbarLinks from "@/helpers/tabbars";
import router from "@/router";
export default {
  name: "TestTable",
  components: { ValidationForm, AuthLayout },
  data() {
    return {
      isDateLoaded: true,
      rolesDictionary: rolesDictionary,
      joinRoles: [5, 10],
      select: null
    };
  },
  computed: {
    selectedRole: {
      get() {
        return this.$store.getters.getCurrentRole;
      }
    },
    availableRoles() {
      return this.$store.getters.getRoles;
    },
    tabbarChecked() {
      return this.$store.getters.getTabbarChecked;
    }
  },
  methods: {
    async selectRole(index) {
      const roleObj = this.availableRoles[index];
      return await this.$store.dispatch("selectRole", {
        role: roleObj.role,
        userGroupId: roleObj.id,
        organization: roleObj.organization_id
      });
      // return true;
    },
    endLogin() {
      this.$store.commit("SET_TABBAR_CHECKED", false);
      this.$store.dispatch("fetchTabbarLinks").finally(() => {
        const avTabs = tabbarLinks.filter(el =>
          el.userRole.includes(this.selectedRole)
        );
        if (avTabs.length >= 0) {
          router.push(avTabs[0].to);
        }
      });
    },
    logout() {
      localStorage.removeItem("token");
      router.go("/login");
    }
  },
  created() {
    this.$store.dispatch("firstFetchUsersRoles").then(() => {
      if (this.availableRoles.length === 1) {
        this.$store.dispatch("firstFetchUsersRoles");
      }
      setTimeout(() => (this.isDateLoaded = false), 1000);
    });
  }
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .authoriz__btn_choose_role {
    margin-left: 110px;
  }
}
</style>
