<template>
  <TestTable />
</template>

<script>
import TestTable from "@/components/tables/TestTable";
export default {
  name: "Home",
  components: { TestTable }
};
</script>
